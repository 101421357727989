<template>
  <div class="m_registrationNotice">
    <div class="m_header-step">
      <m-step :active="active" :data="stepList" />
    </div>
    <!--    报名须知-->
    <template v-if="active === 1">
      <div class="m_body">
        <div class="m_body_text">请阅读本须知</div>
        <div class="m_body_box">
          <div class="m_body_box_title">
            <template v-if="singType === 1 || singType === 2">
              <div class="notice-title" v-if="active === 1">
                {{
                  isCurrentYearEnum === 1
                    ? "2024年度继续教育网授报名须知"
                    : "补修年度网授报名须知"
                }}
              </div>
              <div class="notice-title" v-if="active === 3 || active === 4">
                四川省执业药师继续教育报名
              </div>
            </template>
          </div>
          <div class="m_body_box_content">
            <div v-if="isCurrentYearEnum === 1">
              <p>
                亲爱的学员，欢迎您参加由四川中科信息技术专修学院举办的2024年度执业药师继续教育网络培训。四川中科信息技术专修学院为四川省执业药师协会遴选的合规施教机构之一，为四川省人社厅评定的四川省专业技术人员继续教育基地。为加强继教培训流程管理，提高教学质量，报名前请认真阅读报名须知，注意以下事项：
              </p>
              <p>一、培训形式及时间安排：</p>
              <p>
                （一）2024年度执业药师继续教育网络培训统一采取网络课程培训的形式。
              </p>
              <p>
                （二）时间安排<br />
                报名时间：2024年5月24日早9:00至2025年5月24日下午17:00<br />
                学习时间：2024年5月24日早9:00至2025年6月24日下午17:00<br />
              </p>
              <p>二、基本信息填写</p>
              <p>
                注册个人信息：学员须认真、准确的填写个人信息，如个人信息有误，将影响后期打印学时证明。
              </p>
              <p>三、收费标准与发票</p>
              <p>
                2024年度继续教育费用（非协会会员）：费用为每人200元/人/年，由四川中科信息技术专修学院收取并提供税务部门开具的增值税普票。
              </p>
              <p>四、其他事项</p>
              <p>（一）缴费成功后，请记录下交易流水号或截图界面以便于查询；</p>
              <p style="color: #e3211f">
                （二）因个人原因未能在学习时间内完成课程或报错考试年份，无法退返该年度会费/培训费，请在缴费前确认好您的报名年份与相关信息。
              </p>
              <p>五、咨询电话： 028-85531022</p>
            </div>
            <div v-if="isCurrentYearEnum === 0">
              <p>
                亲爱的学员，欢迎您参加由四川中科信息技术专修学院举办的执业药师继续教育补修学习。四川中科信息技术专修学院为四川省执业药师协会遴选的合规施教机构之一，为四川省人社厅评定的四川省专业技术人员继续教育基地。为加强继教培训流程管理，提高教学质量，报名前请认真阅读报名须知，注意以下事项：
              </p>
              <p>一、培训形式及时间安排：</p>
              <p>
                （一）本次往年度执业药师继续教育补修统一采取网络课程培训的形式。
              </p>
              <p>
                （二）时间安排<br />
                报名时间：2024年5月24日早9:00至2025年5月24日下午17:00<br />
                学习时间：2024年5月24日早9:00至2025年6月24日下午17:00<br />
              </p>
              <p>二、基本信息填写</p>
              <p>
                注册个人信息：学员须认真、准确的填写个人信息，如个人信息有误，将影响后期打印学分证明。
              </p>
              <p>三、收费标准与发票</p>
              <p>
                补修往年继续教育收费标准为210元/人/年，培训发票由四川中科信息技术专修学院为学员提供税务部门开具的增值税普票。
              </p>
              <p>四、其他事项</p>
              <p>（一）缴费成功后，请记录下交易流水号或截图界面以便于查询；</p>
              <p style="color: #e3211f">
                （二）因个人原因未能在学习时间内完成课程或报错考试年份，无法退返该年度会费/培训费，请在缴费前确认好您的报名年份与相关信息。
              </p>
              <p>五、咨询电话： 028-85531022</p>
            </div>
          </div>
          <div class="m_body_box_reading">
            <van-checkbox
              v-model="isReading"
              :checked-color="'#ba2926'"
              shape="square"
              :icon-size="14"
            >
              我已认真阅读并清楚了解该须知内容
            </van-checkbox>
          </div>
        </div>
      </div>
      <div style="height: 60px">
        <div class="m_bottom">
          <van-button
            @click="nextClick(2)"
            class="confirm_btn"
            :disabled="!isReading"
            :class="{ disabled: !isReading }"
            block
          >
            我已确认，前往下一步
          </van-button>
        </div>
      </div>
    </template>
    <!--    选择课程-->
    <template v-if="active === 2">
      <div class="m_body course">
        <div class="m_tip">
          <van-icon name="info-o" color="#E56500" />
          <div class="m_tip_text">
            {{
              `注意:专业科目须选满${setting.min}${
                setting.isXueFeng ? "个学时" : "门课程"
              }，方可提交，不能多选或少选;公需科目无需选课，报名成功后在学习界面自动生成学习内容`
            }}
          </div>
        </div>
        <div class="m_body_text">选择课程</div>
        <div class="m_body_box">
          <van-checkbox-group v-model="selectIds">
            <div
              class="m_course_box"
              v-for="(item, index) in chooseAlternativeList"
              :key="index"
            >
              <div
                class="m_course_box_check"
                @click.capture.stop="checkboxClick(index, item)"
              >
                <van-checkbox
                  :name="item.id"
                  :checked-color="'#ba2926'"
                  :icon-size="14"
                />
              </div>
              <div class="m_course_box_body">
                <div
                  class="m_course_box-body_img"
                  :style="`background-image: url('${JSON.parse(
                    item && item.videoUrl
                  )[0].url.replace('.mp4', '.jpg')}')`"
                >
                  <div class="m_course_box_time" v-if="setting.isXueFeng">
                    {{ item.credits }}课时
                  </div>
                </div>
                <div class="m_course_box_info">
                  <div class="title" :title="item.courseName">
                    <span class="course-type" v-if="item.courseTypeEnum">
                      {{ "[" + item.courseTypeEnum + "]" }}
                    </span>
                    {{ item.courseName }}
                  </div>
                  <div class="m_course_info_item">
                    <img src="@/assets/icon/lecturer-icon.png" />
                    {{ item.unitSpeaker }}
                  </div>
                  <div class="m_course_box_btn" @click="tryListening(item)">
                    <img src="@/assets/icon/play-icon.png" />
                    试听课程
                  </div>
                </div>
              </div>
            </div>
          </van-checkbox-group>
        </div>
      </div>
      <div style="height: 60px">
        <div class="m_bottom m_flex">
          <div class="m_course_info">
            <div class="m_course_num">
              {{ setting && setting.isXueFeng ? "已选学时" : "已选课程" }}：
              <span>
                <span class="num">
                  {{
                    setting && setting.isXueFeng ? totalTime : selectIds.length
                  }}
                </span>
                /{{ setting.min }}
              </span>
            </div>
            <div class="m_course_btn" @click="showCourse">
              <van-icon name="arrow-up" />
              查看已选课程
            </div>
          </div>
          <van-button
            @click="nextClick(3)"
            :disabled="isSubmit"
            :class="{ disabled: isSubmit }"
            class="confirm_btn"
          >
            确认提交
          </van-button>
        </div>
      </div>
    </template>
    <!--    确认付款-->
    <template v-if="active === 3">
      <div class="m_body payment">
        <div class="m_body_text">发票信息</div>
        <div class="m_body_box">
          <van-form ref="vanForm" label-width="75px" input-align="right">
            <van-field
              required
              readonly
              clickable
              v-model="formData.InvoiceType"
              name="InvoiceType"
              label="类别"
              :rules="[{ required: true, message: '请选择类别' }]"
            >
              <template #input>
                <m-picker
                  v-model="formData.InvoiceType"
                  placeholder="请选择类别"
                  :columns="columns"
                />
              </template>
            </van-field>
            <van-field
              v-if="formData.InvoiceType == 0"
              required
              v-model="formData.Invoice"
              name="Invoice"
              label="姓名"
              placeholder="请填写姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              required
              v-model="formData.InvoiceTel"
              name="InvoiceTel"
              label="手机号"
              placeholder="请填写手机号"
              :rules="[
                { required: true, message: '请填写手机号' },
                {
                  pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                  message: '请填写正确手机号',
                },
              ]"
            />
            <van-field
              v-model="formData.InvoiceEmail"
              name="InvoiceEmail"
              label="邮箱"
              placeholder="请填写邮箱"
            />
            <van-field
              v-if="formData.InvoiceType == 1"
              required
              v-model="formData.Invoice"
              name="Invoice"
              label="工作单位"
              placeholder="请填写工作单位"
              :rules="[{ required: true, message: '请填写工作单位' }]"
            />
            <van-field
              v-if="formData.InvoiceType == 1"
              required
              v-model="formData.Unified_credit_code"
              name="Unified_credit_code"
              label="信用代码"
              placeholder="请填写信用代码"
              :rules="[{ required: true, message: '请填写信用代码' }]"
            >
              <template #label>
                <span @click="tipClick">
                  信用代码 <van-icon name="question" color="#B5BCC9" />
                </span>
              </template>
            </van-field>
          </van-form>
        </div>
        <div class="m_body_text">课程列表</div>
        <div class="m_body_box" style="padding: 16px">
          <div
            class="m_course_box"
            v-for="(item, index) in setInfos.list"
            :key="index"
          >
            <div class="m_course_box_body">
              <div class="m_course_box-body_img">
                <img
                  :src="`${JSON.parse(item.videoUrl)[0].url.replace(
                    '.mp4',
                    '.jpg'
                  )}`"
                />
                <div
                  class="m_course_box_time"
                  v-if="setting && setting.isXueFeng"
                >
                  {{ item.credits }}学时
                </div>
              </div>
              <div class="m_course_box_info">
                <div class="title">{{ item.courseName }}</div>
                <div class="m_course_info_item">
                  <img src="@/assets/icon/lecturer-icon.png" />
                  {{ item.unitSpeaker }}
                </div>
                <div class="m_course_box_btn" @click="tryListening(item)">
                  <img src="@/assets/icon/play-icon.png" />
                  试听课程
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 90px">
        <div class="m_bottom m_payment_bottom">
          <div class="notice">
            <div @click.capture.stop="handelNotice">
              <van-checkbox
                v-model="isNoticeReading"
                :checked-color="'#ba2926'"
                shape="square"
                :icon-size="14"
              />
            </div>
            我已阅读<span style="color: #1677fe" @click="handelNotice"
              >《须知内容》</span
            >并知晓同意。
          </div>
          <div class="m_flex">
            <div class="m_course_info">
              <div class="m_course_num">
                总计：
                <span class="num">￥{{ setInfos.money }}</span>
              </div>
              <div class="m_course_text">
                此次报名的费用:{{ setInfos.money }}元
              </div>
            </div>
            <van-button
              @click="confirmPayment(4)"
              :disabled="!isNoticeReading"
              :class="{ disabled: !isNoticeReading }"
              class="confirm_btn"
            >
              确认支付
            </van-button>
          </div>
        </div>
      </div>
    </template>
    <!--    报名结束-->
    <template v-if="active === 4">
      <template v-if="Successful">
        <div class="m_body signEnd">
          <div class="sign-box">
            <img src="@/assets/images/success-img.png" alt="" />
            <div class="sign-tips">恭喜报名成功</div>
            <div class="tips-content">
              学习时间为<br />
              <span>{{ setInfos.startTime }}到{{ setInfos.endTime }}</span>
              <br />
              请合理安排您的学习时间
            </div>
          </div>
        </div>
        <div class="m_bottom">
          <van-button class="confirm_btn" block @click="toSpecialized">
            报名成功，前往学习
          </van-button>
        </div>
      </template>
      <template v-else>
        <div class="m_body signEnd">
          <div class="sign-box">
            <img src="@/assets/images/error-img.png" alt="" />
            <div class="sign-tips">报名失败</div>
            <div class="tips-content">失败原因：支付异常，联系管理员</div>
          </div>
        </div>
        <div class="m_bottom">
          <van-button class="confirm_btn" block> 重新报名 </van-button>
        </div>
      </template>
    </template>
    <!--    已选课程弹窗-->
    <van-popup
      round
      v-model="isShowCourse"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="m_pop">
        <div class="m_pop_title">
          <div>已选课程</div>
          <div @click="showCourse">
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="m_pop_body">
          <template v-if="selectedList && selectedList.length > 0">
            <div
              class="m_pop_body_box"
              v-for="(item, index) in selectedList"
              :key="index"
            >
              <div class="m_pop_body_box_num">{{ item.credits }}<br />课时</div>
              <div class="m_pop_body_box_info">
                <div class="title">{{ item.courseName }}</div>
                <div class="m_pop_body_box_info_item">
                  <img src="@/assets/icon/lecturer-icon.png" />
                  {{ item.unitSpeaker }}
                </div>
              </div>
            </div>
          </template>
          <van-empty v-else description="未选择课程" />
          <div style="height: 60px"></div>
        </div>
      </div>
    </van-popup>
    <!--    试听课程弹窗-->
    <div
      class="m_popup_mask"
      v-if="tryToSeeVisible"
      @click="tryToSeeVisible = false"
    >
      <div class="m_popup_body" @click.stop="tryToSeeVisible = true">
        <div class="player_box">
          <video-player
            :autoplay="true"
            :is-to-see="true"
            v-if="tryToSeeVisible"
            :player-list="tryToSeePlayer"
          />
        </div>
      </div>
    </div>
    <!--    须知内容弹窗-->
    <div class="m_popup_mask" v-if="isNotice">
      <div class="m_popup_body pop_notice">
        <div class="pop_notice_title">须知内容</div>
        <div class="pop_notice_body">
          <p>
            1.电子发票将在3-5天内推送到系统平台，届时可登录学习平台--“电子发票”窗口下载打印.
          </p>
          <p>
            2.如需报销，请提供准确的单位名称和税号，电子发票一但生成，不能再修改对应信息
          </p>
        </div>
        <div class="pop_notice_btn" @click="noticeClick">我已知晓</div>
      </div>
    </div>
    <!--    支付弹窗-->
    <div class="m_popup_mask" v-if="isPayment">
      <div class="m_popup_body pop_payment" @click.stop="isPayment = true">
        <div class="pop_payment_title">
          <van-icon name="warning-o" />
          请使用微信扫码支付费用
        </div>
        <div class="pop_payment_images" ref="qrcodes"></div>
        <div class="pop_payment_info">
          <div class="info_item">
            <span class="title">报名信息</span>
            <span class="value">{{ setInfos.year }}年度继续教育网授</span>
          </div>
          <div class="info_item">
            <span class="title">支付费用</span>
            <span class="value">{{ setInfos.money }}元</span>
          </div>
        </div>
        <div class="pop_payment_tip">
          在线支付成功后，24小时内开通课程，请从协会网站首页的”学员登录“版块登录学习。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mStep from "@/components/m_step.vue";
import mPicker from "@/components/m_picker.vue";
import VideoPlayer from "@/components/videoPlayer.vue";
import * as storage from "@/utils/storage";
import {
  singUpInfoAddSingUp,
  singUpInfoConsentNotice,
  singUpInfoCourseList,
  singUpInfoGetTrainingPlanByIdInfo,
  singUpInfoPayMoney,
  singUpInfoValidationPayMoney,
} from "@/api/registerController";
import QRCode from "qrcodejs2";
export default {
  components: { VideoPlayer, mPicker, mStep },
  name: "m_registrationNotice",
  data() {
    return {
      ValidationPay: null,
      Successful: false,
      setInfos: {},
      qrtitle: "",
      active: 1,
      stepList: ["报名须知", "选择课程", "确认付款"],
      isReading: false,
      setting: {},
      chooseAlternativeList: [],
      tryToSeeVisible: false,
      tryToSeePlayer: "",
      selectIds: [],
      isShowCourse: false,
      formData: {
        InvoiceType: 0,
        Invoice: undefined,
        InvoiceTel: undefined,
        InvoiceEmail: undefined,
        Unified_credit_code: undefined,
        employer: undefined,
      },
      columns: [
        {
          label: "个人",
          value: 0,
        },
        {
          label: "单位",
          value: 1,
        },
      ],
      isNoticeReading: false,
      isNotice: false,
      isPayment: false,
    };
  },
  activated() {
    const that = this;
    if (this.active === 1) {
      // this.centerDialogVisible = true;
    } else if (this.active === 2) {
      // 步骤2 初始数据获取
      singUpInfoGetTrainingPlanByIdInfo({
        trainingPlanId: that.yearId,
      }).then((_res) => {
        that.setting = _res.data;
      });
      singUpInfoCourseList({ trainingPlanId: that.yearId }).then((_res) => {
        that.chooseAlternativeList = _res.data;
      });
    }
  },
  watch: {
    active: function (newV) {
      const that = this;
      if (newV === 2) {
        // 步骤2 初始数据获取
        singUpInfoGetTrainingPlanByIdInfo({
          trainingPlanId: that.yearId,
        }).then((_res) => {
          that.setting = _res.data;
        });
        singUpInfoCourseList({ trainingPlanId: that.yearId }).then((_res) => {
          that.chooseAlternativeList = _res.data;
        });
      }
    },
  },
  computed: {
    selectedList() {
      return this.chooseAlternativeList.filter((item) =>
        this.selectIds.includes(item.id)
      );
    },
    totalTime() {
      if (this.selectedList.length > 0) {
        let count = 0;
        this.selectedList.map((item) => {
          count += Number(item.credits);
        });
        return count;
      } else {
        return 0;
      }
    },
    isSubmit() {
      if (this.totalTime < this.setting.max && this.setting.isXueFeng) {
        return true;
      }
      if (this.selectIds.length < this.setting.max && !this.setting.isXueFeng) {
        return true;
      }
      return false;
    },
    chooseCredit() {
      if (this.setting && this.setting.isXueFeng) {
        if (this.chooseList.length > 0) {
          let count = 0;
          this.chooseList.map((item) => {
            count += Number(item.credits);
          });
          return count;
        } else {
          return 0;
        }
      } else {
        if (this.chooseList.length > 0) {
          let count = 0;
          this.chooseList.map(() => {
            count += Number(1);
          });
          return count;
        } else {
          return 0;
        }
      }
    },
    singType() {
      return storage.getStore("singType");
    },
    yearId() {
      return storage.getStore("yearId");
    },
    isCurrentYearEnum() {
      return storage.getStore("isCurrentYearEnum");
    },
  },
  methods: {
    // 报名成功 跳转学习页面
    toSpecialized() {
      this.keepAliveNavigation("/specialized");
    },
    //
    tipClick() {
      this.$toast({
        message: "请咨询财务人员，开具抬头为工作单位的必填项",
        duration: 5000,
        overlay: true,
        closeOnClick: true,
        closeOnClickOverlay: true,
      });
    },
    nextClick(type) {
      var that = this;
      if (type === 2) {
        singUpInfoConsentNotice({
          trainingPlanId: that.yearId,
          singType: that.singType,
        }).then(async () => {
          this.active = 2;
        });
      } else if (type === 3) {
        //提交
        /*
         * idList @选课ID列表
         * trainingId
         * invoiceTypeEnum @发票抬头类型:0、姓名;1、工作单位
         * isInvoiceEnum @是否需要发票:1、是;0、否
         * invoice @发票抬头
         * invoiceTel @发票接收电话
         * invoicemail @发票邮箱
         * unifiedcreditcode @纳税人识别或统一信用代码证
         * isH5Enum @是否H5支付:0否。1是
         * */
        singUpInfoAddSingUp({
          idList: that.selectIds,
          trainingId: that.yearId,
          invoiceTypeEnum: 0,
          isInvoiceEnum: 0,
          isH5Enum: 1,
        }).then((res) => {
          this.setInfos = res.data;
          if (res.data.state === 1) {
            this.active = 3;
            document.documentElement.scrollTo(0, 0);
          } else if (res.data.state === 2) {
            this.Successful = true;
            this.active = 4;
          }
        });
      } else {
        this.active = type;
      }
    },
    // 选择课程
    checkboxClick(_index, item) {
      if (this.selectIds.includes(item.id)) {
        this.selectIds = this.selectIds.filter((data) => data !== item.id);
      } else {
        if (
          this.totalTime + Number(item.credits) > this.setting.max &&
          this.setting.isXueFeng
        ) {
          this.$notify({
            message: `选择课时超过${this.setting.max}课时`,
            type: "warning",
          });
          return false;
        }
        if (
          this.selectIds.length >= this.setting.max &&
          !this.setting.isXueFeng
        ) {
          this.$notify({
            message: `选择课程超过了${this.setting.max}门`,
            type: "warning",
          });
          return false;
        }
        this.selectIds.push(item.id);
      }
    },
    // 查看已选课程
    showCourse() {
      this.isShowCourse = !this.isShowCourse;
    },
    // 点击试听课程
    tryListening(item) {
      this.tryToSeePlayer = item.videoUrl;
      this.tryToSeeVisible = true;
    },
    // 勾选支付须知
    handelNotice() {
      if (!this.isNoticeReading) {
        this.isNotice = true;
      } else {
        this.isNoticeReading = false;
      }
    },
    // 点击我已知晓
    noticeClick() {
      this.isNotice = false;
      this.isNoticeReading = true;
    },
    //   点击确认支付
    confirmPayment() {
      const that = this;
      that.$refs.vanForm
        .validate()
        .then(() => {
          singUpInfoPayMoney({
            idList: that.selectIds,
            trainingId: that.yearId,
            invoiceTypeEnum: that.formData.InvoiceType,
            invoice: that.formData.Invoice,
            invoiceTel: that.formData.InvoiceTel,
            invoicemail: that.formData.InvoiceEmail,
            unifiedCreditCode: that.formData.Unified_credit_code,
            isInvoiceEnum: 1,
            isH5Enum: 1,
          }).then(async (res) => {
            that.isPayment = true;
            that.$nextTick(() => {
              new QRCode(that.$refs.qrcodes, {
                text: res.data.codeUrl, // 需要转换为二维码的内容
                width: 170,
                height: 170,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
              document.getElementsByClassName("zxing-img")[0].title =
                "支付二维码";
            });

            this.ValidationPay = setInterval(async () => {
              await singUpInfoValidationPayMoney({ id: res.data.id }).then(
                (_res) => {
                  if (_res.data) {
                    clearInterval(this.ValidationPay);
                    this.Successful = true;
                    that.isPayment = false;
                    this.active = 4;
                  }
                }
              );
            }, 3000);
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.m_registrationNotice {
  .m_header-step {
    background: #7c3e3d;
    padding: 16px 14px;
  }
  .m_body {
    padding: 10px 14px;
    .m_body_text {
      color: #818496;
      font-size: 12px;
    }
    .m_body_box {
      padding: 16px;
      margin-top: 6px;
      background: #ffffff;
      border-radius: 8px;
      .m_body_box_title {
        font-size: 18px;
        text-align: center;
      }
      .m_body_box_content {
        background: #f2f2f2;
        margin-top: 12px;
        padding: 10px 14px;
        border-radius: 6px;
        p {
          font-size: 14px;
          color: #505363;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .m_body_box_reading {
        margin-top: 12px;
      }
      .m_course_box {
        background: rgba(186, 41, 38, 0.01);
        border: 1px solid #eceef1;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        .m_course_box_body {
          display: flex;
          margin-left: 8px;
          width: calc(100% - 24px);
          .m_course_box-body_img {
            position: relative;
            width: 100px;
            height: 80px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 4px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
            .m_course_box_time {
              position: absolute;
              top: 0;
              font-size: 12px;
              border-radius: 0 0 4px 0;
              padding: 1px 6px;
              background: #549a54;
              color: #ffffff;
            }
          }
          .m_course_box_info {
            margin-left: 8px;
            width: calc(100% - 108px);
            .title {
              font-size: 12px;
              line-height: 1.5;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-weight: bold;
              .course-type {
                color: #ba2926;
                font-weight: bold;
              }
            }
            .m_course_info_item {
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 12px;
              img {
                width: 14px;
                height: 14px;
                vertical-align: text-bottom;
              }
            }
            .m_course_box_btn {
              padding: 2px 15px;
              font-size: 12px;
              text-align: center;
              background: rgba(186, 41, 38, 0.05);
              border: 1px solid #ba2926;
              color: #ba2926;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                margin-right: 5px;
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
  .m_bottom {
    padding: 8px 14px;
    background: #ffffff;
    position: fixed;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    bottom: 0;
    border-top: 1px solid #eceef1;
    z-index: 9999;
    .confirm_btn {
      background: #ba2926;
      color: #ffffff;
      border-radius: 4px;
      height: 37px;
      font-size: 13px;
      &.disabled {
        color: #b5bcc9;
        opacity: 1;
        background: rgba(181, 188, 201, 0.2);
      }
    }
    .m_course_info {
      font-size: 12px;
      .m_course_num {
        color: #818496;
        span {
          color: rgba(0, 0, 0, 0.7);
          font-size: 16px;
        }
        .num {
          color: #ba2926;
        }
      }
      .m_course_btn {
        color: #1677fe;
      }
      .m_course_text {
        color: #818496;
      }
    }
  }
  .m_tip {
    display: flex;
    align-items: baseline;
    padding: 8px 12px;
    border-radius: 8px;
    background: linear-gradient(
        0deg,
        rgba(229, 101, 0, 0.1),
        rgba(229, 101, 0, 0.1)
      ),
      #ffffff;
    .m_tip_text {
      margin-left: 5px;
      font-size: 12px;
      color: #e56500;
    }
  }
  .course {
    .m_body_text {
      margin-top: 10px;
    }
  }
  .payment {
    .m_body_text {
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
    .m_course_box_body {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
  .signEnd {
    padding: 70px;
    .sign-box {
      text-align: center;
      img {
        width: 100px;
      }
      .sign-tips {
        margin-top: 17px;
        color: #3d3d3d;
        font-size: 18px;
      }
      .tips-content {
        margin-top: 4px;
        color: #818496;
        font-size: 14px;
        span {
          color: #1677fe;
        }
      }
    }
  }
  .m_payment_bottom {
    z-index: 1000;
    height: 90px;
    .notice {
      display: flex;
      align-items: center;
      div {
        margin-right: 5px;
      }
    }
  }
  .m_flex {
    display: flex;
    justify-content: space-between;
  }
  .m_pop {
    padding: 12px 16px;
    height: 100%;
    box-sizing: border-box;
    .m_pop_title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      border-bottom: 1px solid #ebebeb;
      height: 30px;
      line-height: 30px;
    }
    .m_pop_body {
      height: calc(100% - 38px);
      overflow-y: auto;
      margin-top: 8px;
      &::-webkit-scrollbar {
        display: none;
      }
      .m_pop_body_box {
        display: flex;
        padding: 12px 16px;
        margin-bottom: 8px;
        background: #f4f6f8;
        border-radius: 4px;
      }
      .m_pop_body_box_num {
        color: #ffffff;
        background: #549a54;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        margin-right: 8px;
      }
      .m_pop_body_box_info {
        width: calc(100% - 48px);
        .title {
          font-size: 14px;
        }
        .m_pop_body_box_info_item {
          font-size: 12px;
          color: #818496;
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
.m_popup_mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  .m_popup_body {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(100% - 64px);
    padding: 28px 24px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
  }
  .pop_notice {
    background-image: url("/static/images/pop-icon-01.png"),
      url("/static/images/pop-icon-02.png");
    background-size: 140px 140px, 172px;
    background-repeat: no-repeat;
    background-position: calc(100% + 30px) -30px, -80px calc(100% + 80px);
    .pop_notice_title {
      color: #21252e;
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px;
    }
    .pop_notice_body {
      color: #505363;
      font-size: 14px;
      margin-bottom: 32px;
    }
    .pop_notice_btn {
      color: #ffffff;
      background: #ba2926;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 8px;
      text-align: center;
    }
  }
  .pop_payment {
    .pop_payment_title {
      font-size: 12px;
      color: #818496;
      text-align: center;
    }
    .pop_payment_images {
      text-align: center;
      margin: 15px auto 0;
      width: 170px;
      height: 170px;
      padding: 10px;
      background: linear-gradient(to left, #d8d8d8, #d8d8d8) left top no-repeat,
        linear-gradient(to bottom, #d8d8d8, #d8d8d8) left top no-repeat,
        linear-gradient(to left, #d8d8d8, #d8d8d8) right top no-repeat,
        linear-gradient(to bottom, #d8d8d8, #d8d8d8) right top no-repeat,
        linear-gradient(to left, #d8d8d8, #d8d8d8) left bottom no-repeat,
        linear-gradient(to bottom, #d8d8d8, #d8d8d8) left bottom no-repeat,
        linear-gradient(to left, #d8d8d8, #d8d8d8) right bottom no-repeat,
        linear-gradient(to left, #d8d8d8, #d8d8d8) right bottom no-repeat;
      background-size: 2px 14px, 14px 2px, 2px 14px, 14px 2px;
      /deep/ img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .pop_payment_info {
      margin-top: 15px;
      .info_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        .title {
          color: #505363;
        }
        .value {
          color: #21252e;
        }
      }
    }
    .pop_payment_tip {
      margin-top: 16px;
      font-size: 12px;
      padding: 8px;
      border-radius: 8px;
      color: #e56500;
      background: rgba(229, 101, 0, 0.1);
    }
  }
}
.player_box {
  height: 250px;
}
</style>
