<template>
  <div class="step">
    <template v-for="(item, index) in data">
      <div class="first" :class="{ active: active > index+1 }" :key="'step'+index">
        <van-icon v-if="active > index+1" name="success" size="12" color="#7c3e3d" />
        <div v-else class="first-index">{{ index + 1 }}</div>
        <div class="first-title">{{ item }}</div>
      </div>
      <div
        class="line"
        :class="{ active: active > index+1 }"
        :key="index"
        v-if="index + 1 !== data.length"
      ></div>
    </template>
  </div>
</template>
<script>
export default {
  name: "mStep",
  props: {
    active: {
      default: 1,
    },
    data: {
      default: [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 32px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 12px;
}
.first {
  position: relative;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  text-align: center;
  line-height: 20px;
  margin: 0 5px;
  .first-title {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    left: 50%;
    top: calc(100% + 15px);
  }
  &.active {
    background: #ffffff;
  }
}
.line {
  flex: 1;
  background: repeating-linear-gradient(
    to right,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.4) 10px,
    transparent 0px,
    transparent 15px
  ); /* 创建一条虚线背景 */
  height: 2px; /* 设置元素的高度为1像素 */
  &.active {
    background: #ffffff;
  }
}
</style>
