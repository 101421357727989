<template>
  <div class="picker">
    <div
      class="picker_value"
      :style="`color: ${valueText ? '' : '#c8c9cc'}`"
      @click="isShow = true"
    >
      {{ valueText || placeholder }}
      <van-icon name="arrow" />
    </div>
    <van-popup v-model="isShow" position="bottom">
      <van-picker
        :value-key="propsOption.label"
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="confirm"
        @cancel="cancel"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "mPicker",
  props: {
    value: {
      default: undefined,
    },
    placeholder: {
      default: "",
    },
    columns: {
      default: [],
    },
    propsOption: {
      type: Object,
      default: () => {
        return { value: "value", label: "label", children: "children" };
      },
    },
  },
  computed: {
    valueText() {
      return this.value ? this.getChecked(this.value) : "";
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    getChecked(value) {
      const list = JSON.parse(JSON.stringify(this.columns));
      return list.find((item) => item[this.propsOption.value] == value)[
        this.propsOption.label
      ];
    },
    confirm(data) {
      this.$emit("input", data[this.propsOption.value]);
      this.isShow = false;
    },
    cancel() {
      this.isShow = false;
    },
  },
};
</script>
<style scoped lang="scss">
.picker {
  width: 100%;
  .picker_value {
    width: 100%;
  }
}
</style>
